import PropTypes from 'prop-types';
import * as React from 'react';
// import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from 'gatsby';
// import { ChevronDownIcon } from '@bgea-js/react-icons';
import { HeaderPage } from '@bgea-js/design-library';
import { ProjectList } from '@bgea-js/form-templates';
import Layout from '../components/Layout';
import * as Ui from '@bgea-js/react-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';
import { LandingPageProjects } from '../constants/project';

function Page({ data }) {
  let meta = {
    title: 'Billy Graham Evangelistic Association | Giving Areas',
    description:
      'We rely on the prayers and gifts of friends like you. Your support is being used by God to transform lives around the world.',
    socialTitle:
      'Make an online Gift to the Billy Graham Evangelistic Association',
  };
  let styles = {};
  const projects = data?.projects?.nodes
    .filter((item) => item?.frontmatter?.image && !LandingPageProjects.includes(item?.frontmatter?.projectCode))
    .map((item) => ({
      ...item?.frontmatter,
      projectId: item?.frontmatter?.projectCode,
      image: item?.frontmatter?.image?.childImageSharp?.fixed?.src,
    }));
  // projects.sort((a, b) => a?.order - b?.order);
  return (
    <Layout
      meta={meta}
      styles={styles}
      header={
        <HeaderPage
          title="Will you help reach hurting people around the world with the hope of Jesus Christ?"
          subtitle="Your gift today will share the Good News with others and point them to the love of our Heavenly Father."
          imageWidth={4}
          className="is-hidden-mobile"
        >
          <StaticImage src={'../images/header.jpg'} alt="Prayer counseling" />
        </HeaderPage>
      }
    >
      <Ui.Section className="p-0">
        <Ui.Container>
          <Ui.Columns centered={true}>
            <Ui.Columns.Column>
              <Ui.Section>
                <ProjectList
                  checkoutPath={'/checkout'}
                  currencyErrorMessage={
                    'Please enter an amount of at least $5 to continue.'
                  }
                  // headerText={'Or Give to Specific Areas'}
                  projects={projects}
                  siteId={'bgea'}
                />
              </Ui.Section>
            </Ui.Columns.Column>
          </Ui.Columns>
        </Ui.Container>
      </Ui.Section>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.shape({}),
};

export const pageQuery = graphql`
  query {
    projects: allMdx(
      filter: { frontmatter: { order: { ne: 0 } } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        body
        fields {
          slug
        }
        frontmatter {
          title
          slug
          image {
            childImageSharp {
              gatsbyImageData
              original {
                height
                width
                src
              }
              fixed(width: 249, height: 201, quality: 95, cropFocus: CENTER) {
                height
                width
                src
              }
            }
            internal {
              mediaType
            }
            base
            relativePath
          }
          description
          projectCode
          order
        }
      }
    }
  }
`;

export default Page;

export const Head = ({ location, params, data, pageContext }) => {
  return (
    <Seo
      title="Billy Graham Evangelistic Association | Giving"
      description="Your gift today will share the Good News with others and point them to the love of our Heavenly Father."
      pathname={location.pathname}
    />
  );
};
